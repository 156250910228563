<template>
    <div class="page-wrap">
      <div class="page-main flex-column">
        <SubNav :nav="subNav" :router="true"></SubNav>
        <div class="main padding-20"><router-view></router-view></div>
        <el-backtop target=".main">
          <i class="el-icon-top"></i>
        </el-backtop>
      </div>  
    </div> 
</template>

<script>
import SubNav from '@/components/SubNav.vue'
export default {
  name: 'Entry',
  components: { SubNav },
  data() {
    return {
     subNav:[{name:'周度预测模型',value:'/outDivisors/assignment/week'},{name:'月度预测模型',value:'/outDivisors/assignment/month'}],
    }
  },
  created(){

  },
  methods:{
   
  },
  watch: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
